import ServiceStationCard from './ServiceStationCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import ServiceStationListUtil,{IServiceStationListDataObj} from './serviceStationListUtil';
export default defineComponent ({
    name: 'ServiceStationList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IServiceStationListDataObj=reactive<IServiceStationListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.ToolsProviderApi.buildUrl('/serviceStation/uploadFiles')
                },
                downloadParams:{
                    sourceName:'服务站账号模板.xlsx',
                    downLoadFileName:'服务站账号模板.xlsx',
                    action:utils.Api.buildUrl('/serviceStation/downLoadResourceFile')
                }
            },
            pageList: {
                queryParam: {},
                modelComp: ServiceStationCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/serviceStation/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ServiceStationListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'启用'},{value:1,label:'停用'}]
                }
            }
        })
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        const afterResult=(res:any,uploadInst:any)=>{
            dataObj.pageListRef.queryHandler(true);
        }
        return{
            ...toRefs(dataObj),comboSelect,importHandler,afterResult
        }
    }
});