import BaseBean from "@/utils/BaseBean";

export interface IServiceStationListDataObj {
    utilInst:ServiceStationListUtil
    refMap:Map<string,any>
    importGuidParams:any
    pageListRef:any
    pageList: any
}

export default class ServiceStationListUtil extends BaseBean{
    public dataObj:IServiceStationListDataObj

    constructor(proxy:any,dataObj:IServiceStationListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}